@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./fonts/AvenirLTStd-Light.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./fonts/AvenirLTStd-Black.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}

body {
  background-color: #46c56a;
  height: 100%;
  width: 100%;
  font-family: "Bebas Neue";
}

.App {
  text-align: left;
  color:white;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-right: 2.5%;
  padding-left: 2.5%;
}

.header a.logo h1 {
  font-size: 1.5em;
  font-weight: bold;
  font-family: "Bebas Neue";
}

.header a svg {
  width: 20px;
  height: 20px;
  fill: white;
  margin: 0px;
  padding: 0px;
  display: inline-block;
  vertical-align: middle;
}

.header a {
  margin: 5px;
  color: white;
  text-decoration: none;
}

.header a h1 {
  margin: 0;
  padding: 0;
}

.header .logo img {
  width: 5rem;
}

nav {
  /* padding-top: 10px; */
  /*background-color: #f8f9fa;  Fondo opcional */
  align-self: center;
  vertical-align: middle;
  display: flex;
}

.menu {
  align-self: center;
  vertical-align: middle;
  list-style-type: none; /* Quita los puntos de la lista */
  display: flex; /* Coloca los <li> en una fila horizontal */
  margin: 0;
  padding: 0;
}

.menu li {
  margin-left: 20px; /* Espacio entre los <li> */
}

.menu li a {
  text-decoration: none; /* Quita el subrayado del enlace */
  color: white; /* Color del texto */
  /* padding: 10px; */
  font-weight: bold;
}

.menu li a:hover {
  text-decoration: underline;
}

.home {
  text-align: center;
  max-width: 42rem;
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.home h1 {
  font-size: clamp(5.65rem, 18.5vw, 9.7rem);
  line-height: clamp(4.25rem, 13.765vw, 7.25rem);
}

.footer {
  text-align: center;
}

.footer a {
  padding: 0 5px;
}

.footer a svg{
  height: 36px;
  width: 32px;
  fill : white;
}

.page-container {
  padding-left: 2.5%;
  padding-right: 2.5%;
}

.page-container iframe {
  border:none;
  width: 100%;
  min-height: 400px;
  height: calc(120vh - 400px);
}

.form-page {
  max-width: 42rem;
  margin: auto;
}

.form-page p {
  font-weight: 700;
}

.form-page iframe h3 {
  color:white;
}

.font-avenir {
  font-family: "Avenir";
}